import React from "react";
import "./TermConditionNew.css";
import CustomerT2Cjson from "./customerT2C.json";
import "../../DriverTNC/DriverT&C/subscription.css";
function TermConditionNew() {
  const renderContent = (content) => {
    return content.map((item, index) => {
      // console.log(item);
      switch (item.TYPE) {
        case "heading":
          // Render heading
          return (
            <h4 key={index} className="headingTopic">
              {item.VALUE.map((val, idx) =>
                val.TYPE === "text" ? val.VALUE : null
              ).join("")}
            </h4>
          );

        case "paragraph":
          // Render paragraph
          return (
            <div>
              {item.VALUE.map((item, index) => (
                <p key={index} className="paraTag">
                  {item.TYPE === "text" && item.VALUE}
                  {item.TYPE === "html" && (
                    <span dangerouslySetInnerHTML={{ __html: item.VALUE }} />
                  )}
                </p>
              ))}
            </div>
          );
        case "list":
          // Render ordered or unordered list depending on content
          return (
            <ul className="listStyle">
              {item.VALUE.map((val, idx) => {
                if (val.TYPE === "text") {
                  return <li key={`${item.id}-${idx}`}>{val.VALUE}</li>;
                } else if (val.TYPE === "html") {
                  return (
                    <li
                      key={`${item.id}-${idx}`}
                      dangerouslySetInnerHTML={{ __html: val.VALUE }}
                    />
                  );
                }
                return null; // Safeguard for unknown types
              })}
            </ul>
          );

        case "heading_list":
          // Render heading in the paragraph
          return (
            <>
              {item.VALUE.map(
                (val, idx) =>
                  val.TYPE === "text" && (
                    <p
                      style={{ fontWeight: "800" }}
                      className="headingList"
                      key={item.id}
                    >
                      {val.VALUE}
                    </p>
                  )
              )}
            </>
          );

        case "sub_list":
          // Render list which is inside the list
          return (
            <>
              <ul className="sub_list">
                {item.VALUE.map((val, idx) => {
                  if (val.TYPE === "text") {
                    return <li key={`${item.id}-${idx}`}>{val.VALUE}</li>;
                  } else if (val.TYPE === "html") {
                    return (
                      <li
                        key={`${item.id}-${idx}`}
                        dangerouslySetInnerHTML={{ __html: val.VALUE }}
                      />
                    );
                  }
                  return null; // Safeguard for unknown types
                })}
              </ul>
            </>
          );

        case "list_of_sub_list":
          // Render list which is inside the list
          return (
            <>
              <ul className="sub_list2">
                {item.VALUE.map(
                  (val, idx) =>
                    val.TYPE === "text" && (
                      <li key={`${item.id}-${idx}`}>{val.VALUE}</li>
                    )
                )}
              </ul>
            </>
          );

        case "table":
          return (
            <div className="sub_list2">
              <table key={index} className="customTableForCustomer">
                <tbody>
                  {item.VALUE.map(
                    (row, rowIndex) =>
                      row.TYPE === "table-row" && (
                        <tr key={`row-${rowIndex}`} className="tableRow">
                          {row.VALUE.map(
                            (cell, cellIndex) =>
                              cell.TYPE === "table-cell" && (
                                <td
                                  key={`cell-${rowIndex}-${cellIndex}`}
                                  className="tableCell"
                                >
                                  {cell.VALUE.map((content, contentIndex) => {
                                    if (content.TYPE === "paragraph") {
                                      return content.VALUE.map(
                                        (paragraph, paragraphIndex) =>
                                          paragraph.TYPE === "text" ? (
                                            <span
                                              key={`text-${contentIndex}-${paragraphIndex}`}
                                              className="tableText"
                                            >
                                              {paragraph.VALUE}
                                            </span>
                                          ) : null
                                      );
                                    }
                                    return null; // Handle only known content types
                                  })}
                                </td>
                              )
                          )}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          );

        default:
          return null; // If the type is unknown, do nothing
      }
    });
  };

  // Render the content from the subscription data
  return (
    <div className="container_For_Subscription_B2B_DriverTnc containerTermsCondition">
      {renderContent(CustomerT2Cjson.VALUE[0].VALUE)}{" "}
      {/* Render the body content */}
    </div>
  );
}

export default TermConditionNew;
