import "./App.css";
// This Css Added in index.html if any css error in this packages then remove from index.html and uncomment these two css link
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// Importing Packages
import React, {
  lazy,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Toaster } from "react-hot-toast";
import CleverTap from "clevertap-web-sdk";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  Route,
  Routes,
} from "react-router-dom";
import { getClientId } from "./utils/getClientId.js";
// context
import { HamburgerContext } from "./context/HamburgerContextProvider";
import { ReferralContext } from "./context/ReferralContextProvider";
import { BookingFlowParaContext } from "./context/BookingFlowParaProvider";
import { BookingFlowDataContext } from "./context/BookingFlowDataContextProvider";
import { InsuranceClaimContext } from "./context/InsuranceClaimContextProvider";
import { WashSubscriptionContext } from "./context/WashSubscriptionContextProvider";
import { ApplicationContext } from "./context/ApplicationContextProvider";
import { carServiceContext } from "./context/CarServiceContextProvider";

// components Import
import ContactUs from "./pages/ContactUs/ContactUs";
import BookingBlock from "./BookingFlow/BookingBlock/BookingBlock";
import TrackingPage from "./MyGate/TrackingPage";
import MyGateDriverHelp from "./pages/HelpPage/MyGateDriverHelp";
import MyGateWashHelp from "./pages/HelpPage/MyGateWashHelp";
import Fuelprice from "./ApplicationLink/Fuelprice/Fuelprice";
import CarService from "./ApplicationLink/MyGateCarService/CarService/CarService";
import CarServiceTrack from "./MyGate/CarServiceTrack";
import Gurgaon from "./pages/CityPage/Gurgaon";
import DriveuDaily from "./pages/DriveuDaily/DriveuDaily";
import DriveULandingPage from "./pages/DriveuDaily/DriveULandingPage";
import DailyThankyou from "./pages/DriveuDaily/DailyThankyou";
import TrackDuDaily from "./DriveUDaily/TrackDuDaily/TrackDuDaily";
import DuBookingTrack from "./DriveUDaily/DuBookingTrack/DuBookingTrack";
import DuDailyPartnerLandingPage from "./pages/DuDailyPartnerPage/DuDailyPartnerLandingPage";
import AllTypesBooking from "./pages/DuDailyPartnerPage/AllTypesBooking";
import StatusPackage from "./pages/DuDailyPartnerPage/StatusPackage";
import AssignedPartnerPage from "./pages/DuDailyPartnerPage/AssignedPartnerPage";
import DuDailyHelp from "./pages/HelpPage/DailyHelp";
import GurgaonAllCity from "./pages/PopularCities/GurgaonAllCity";
import DUOneWayBooking from "./DriveUDaily/DUOneWayBooking/DUOneWayBooking";
import DriverPrivacyPolicy from "./parts/PrivacyPolicy/PrivacyPolicyBlock/DriverPrivacyPolicy";
import { useSearchParams } from "react-router-dom";
import MsilPage from "./OrderPage/MsilPage";
import AccountDelete from "./AccountDelete/AccountDelete";
import TermConditionNew from "./parts/Term&Condition/TermConditionNew/TermConditionNew.jsx";
import EvChargingHelp from "./pages/HelpPage/EvChargingHelp.jsx";

// Lazy Loading

const CarCleaning = lazy(() => import("./CarCleaning/CarCleaning.jsx"));
const WashSubscriptionPage = lazy(() =>
  import("./pages/WashSubscriptionPage/WashSubscriptionPage")
);
const FeedbackForm = lazy(() =>
  import("./ApplicationLink/FeedbackForm/FeedbackForm")
);
const RefundPolicyPage = lazy(() =>
  import("./parts/RefundPolicy/RefundPolicyPage")
);
const WashSubscription = lazy(() =>
  import("./WashSubscription/WashSubscription")
);
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const Hyderabad = lazy(() => import("./pages/CityPage/Hyderabad"));
const Bangalore = lazy(() => import("./pages/CityPage/Bangalore"));
const Ahmedabad = lazy(() => import("./pages/CityPage/Ahmedabad"));
const DelhiNCR = lazy(() => import("./pages/CityPage/DelhiNCR"));
const Kolkata = lazy(() => import("./pages/CityPage/Kolkata"));
const Mumbai = lazy(() => import("./pages/CityPage/Mumbai"));
const Pune = lazy(() => import("./pages/CityPage/Pune"));
const Chennai = lazy(() => import("./pages/CityPage/Chennai"));
const EziDrive = lazy(() =>
  import("./Components/ComparePage/EziDrive/EziDrive")
);
const CallDrivers = lazy(() =>
  import("./Components/ComparePage/CallDrivers/CallDrivers")
);
const DriversOnHire = lazy(() =>
  import("./Components/ComparePage/DriversOnHire/DriversOnHire")
);
const DriversIndia = lazy(() =>
  import("./Components/ComparePage/DriversIndia/DriversIndia")
);

// new import start here

const Tatd = lazy(() => import("./Components/ComparePage/Tatd/Tatd"));
const GetYourDriver = lazy(() =>
  import("./Components/ComparePage/GetYourDriver/GetYourDriver")
);
const DriveAssists = lazy(() =>
  import("./Components/ComparePage/DriveAssists/DriveAssists")
);
const IndianDriver = lazy(() =>
  import("./Components/ComparePage/IndianDrivers/IndianDriver")
);
const NammaDriver = lazy(() =>
  import("./Components/ComparePage/NammaDriver/NammaDriver")
);
const DriverSuvidha = lazy(() =>
  import("./Components/ComparePage/DriverSuvidha/DriverSuvidha")
);
const DriverKatta = lazy(() =>
  import("./Components/ComparePage/DriverKatta/DriverKatta")
);
const Cars24Service = lazy(() =>
  import("./Components/ComparePage/Cars24/Cars24Service")
);

// new import end here

const DomesticDriver = lazy(() =>
  import("./Components/DriverServices/DomesticPage/DomestiCDrivers")
);
const DailyCommute = lazy(() =>
  import("./Components/DriverServices/DailyCommutePage/DailyCommute")
);
const Weekend = lazy(() =>
  import("./Components/DriverServices/WeekendPage/Weekend")
);
const Outstation = lazy(() =>
  import("./Components/DriverServices/OutstationPage/Outstation")
);
const NightDriver = lazy(() =>
  import("./Components/DriverServices/NightDriver/NightDriver")
);
const ShortTrip = lazy(() =>
  import("./Components/DriverServices/ShortTrip/ShortTrip")
);

const TemporaryDriver = lazy(() =>
  import("./Components/HireDriverServices/TemporaryDriver/TemporaryDriver")
);
const HourlyDriver = lazy(() =>
  import("./Components/HireDriverServices/HourlyDriver/HourlyDriver")
);
const Chauffeurs = lazy(() =>
  import("./Components/HireDriverServices/Chauffeurs/Chauffeurs")
);
const DriveUBlack = lazy(() =>
  import("./Components/HireDriverServices/DriveUBlack/DriveUBlack")
);
const HireDriver = lazy(() =>
  import("./Components/HireDriverServices/HireDriver/HireDriver")
);

const SellCar = lazy(() => import("./Components/ServicePage/SellCar/SellCar"));
const CarServicing = lazy(() =>
  import("./Components/ServicePage/CarServicing/CarServicing")
);
const CarDetailing = lazy(() =>
  import("./Components/ServicePage/CarDetailing/CarDetailing")
);
const FastTag = lazy(() => import("./Components/ServicePage/FastTag/FastTag"));
const DriveUCoins = lazy(() =>
  import("./Components/ServicePage/DriveUCoins/DriveUCoins")
);
const RoadSideAssistance = lazy(() =>
  import("./Components/ServicePage/RoadSideAssistance/RoadSideAssistance")
);
const CarWash = lazy(() => import("./Components/ServicePage/CarWash/CarWash"));

const AboutUsPage = lazy(() => import("./Components/AboutUsPage/AboutUsPage"));
const Offers = lazy(() => import("./Components/WorkPage/Offers/Offers"));
const PrivacyPolicyPage = lazy(() =>
  import("./parts/PrivacyPolicy/PrivacyPolicyPage")
);
const ReferralPage = lazy(() =>
  import("./Components/ReferralPage/ReferralPage")
);
const ReferralError = lazy(() =>
  import("./Components/ReferralPage/ReferralError/ReferralError")
);

const CleaningTNC = lazy(() => import("./parts/CleaningTNC/CleaningTNC"));
const TNCPage = lazy(() => import("./pages/TNCPage/TNCPage"));
const DriverTNC = lazy(() => import("./parts/DriverTNC/DriverTNC"));
const B2BTNC = lazy(() => import("./parts/B2BTNC/B2BTnc"));

const ErrorPage = lazy(() => import("./Components/ErrorPage/ErrorPage"));
const OrderPage = lazy(() => import("./OrderPage/OrderPage"));
const MobileInsurance = lazy(() => import("./MobileInsurance/MobileInsurance"));
const LatestProdOffer = lazy(() =>
  import("./Components/WorkPage/Offers/LatestProdOffer/LatestProdOffer")
);
const InsuranceList = lazy(() =>
  import("./ApplicationLink/InsuranceLink/InsuranceList")
);
const FASTagList = lazy(() =>
  import("./ApplicationLink/FASTagLink/FASTagList")
);
const TwitterPage = lazy(() =>
  import("./ApplicationLink/TwitterPage/TwitterPage")
);
const Challan = lazy(() => import("./Challan/Challan"));
const ClaimsPage = lazy(() =>
  import("./MobileInsurance/ClaimsPage/ClaimsPage")
);
const EMICalculator = lazy(() => import("./EMICalculator/EMICalculator"));
const GarageForm = lazy(() =>
  import("./ApplicationLink/CashlessGarage/GarageForm/GarageForm")
);
const GarageList = lazy(() =>
  import("./ApplicationLink/CashlessGarage/GarageList/GarageList")
);
const HelplineLink = lazy(() =>
  import("./ApplicationLink/HelpLine/HelplineLink")
);
const VehicleRecall = lazy(() => import("./VehicleRecall/VehicleRecall"));
const MarshIns = lazy(() => import("./parts/MarshInsurance/MarshIns"));
const InsuranceClaimPage = lazy(() =>
  import("./pages/InsuranceClaimPage/InsuranceClaimPage")
);
const CarBikeRenew = lazy(() =>
  import("./WashSubscription/CarBikeRenew/CarBikeRenew")
);
const LuckyWinner = lazy(() =>
  import("./ApplicationLink/LuckyWinner/LuckyWinner")
);
const DriveuHelp = lazy(() => import("./pages/HelpPage/DriveuHelp"));
const CarWashHelp = lazy(() => import("./pages/HelpPage/CarWashHelp"));
const MarshInsuranceHelp = lazy(() =>
  import("./pages/HelpPage/MarshInsuranceHelp")
);
const VacationPlannerPage = lazy(() =>
  import("./ApplicationLink/VacationPlanner/VacationPlannerPage")
);
const DriverDetails = lazy(() =>
  import("./OrderPage/DriverDetails/DriverDetails")
);
const PaymentDetails = lazy(() =>
  import("./OrderPage/PaymentDetails/PaymentDetails")
);
const PartnerPage = lazy(() => import("./pages/PartnerPage/PartnerPage"));

// Root Function
function App() {
  window.generatedLink = window.generatedLink.replace(
    /(page_title=)[^&]+/,
    "$1" + window.location.pathname
  );

  const [cleverTapId, setCleverTapId] = useState(null);
  const [clientId, setClientId] = useState(null);
  useEffect(() => {
    // Function to get CleverTap ID
    const getCleverTapId = () => {
      const identity = CleverTap.getCleverTapID();
      setCleverTapId(identity);
    };
    // Call the function to get CleverTap ID
    getCleverTapId();
    getClientId().then((clientId) => {
      setClientId(clientId);
    });
  }, []);

  // env variables
  const PROFILE_API_SRC = process.env.REACT_APP_PROFILE_API_SRC;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;

  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const [cookies, setCookie] = useCookies(["userid"]);
  const navigate = useNavigate();
  const checkInsurancePathName = window.location.pathname;
  const checkMobile = window.location.search.split("&");

  // Context API
  const { setIsAuthenticated, setUserName, setShowUserId, setLat, setLng } =
    useContext(BookingFlowParaContext);
  const {
    setUserInfo,
    setInsuranceUserId,
    setDetailsResponse,
    showOnlinePaymentPopUp,
    setShowOnlinePaymentPopUp,
    detailsResponse,
  } = useContext(BookingFlowDataContext);
  const { carServiceData, setCategory } = useContext(carServiceContext);
  const { goToPage, referralId, errorReferral } = useContext(ReferralContext);
  const { washApplicationParamas, setWashApplicationParams, setSource } =
    useContext(WashSubscriptionContext);
  const { handleFalse } = useContext(HamburgerContext);
  const { setBookingId, setUserId } = useContext(InsuranceClaimContext);
  const {
    setCityId,
    setDriverID,
    setImeiNum,
    setMyGateToken,
    setPubName,
    setMyGateBookingID,
    setMyGateWashToken,
    myGatePaymentCheck,
    setAppCarWashTrackSrc,
    setAppUserID,
    setTrackServiceSrc,
    setServiceOrderID,
    setServiceMyGateToken,
    fuelPriceCoordinate,
    setFeedbackScreen,
    setDuDailyParams,
    setDuOrderID,
    setDriverData,

    // msil start here

    setMsilTokenData,

    // msil end here
  } = useContext(ApplicationContext);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (
      cookies.sdk_initialize !== "true" &&
      clientId &&
      cleverTapId &&
      cookies.userid
    ) {
      let data = JSON.stringify({
        pseudo_user_id: clientId,
        clevertap_id: cleverTapId,
      });
      let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: `${BASE_URL}/web/sdk-instance-ids/?user_id=${cookies.userid}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          setCookie("sdk_initialize", res.data.sdk_initialize, { path: "/" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientId, cleverTapId]);

  // useLayoutEffect For Wash Subscription Page
  useLayoutEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page_type = urlParams.get("user_id");
    const page_type_src = urlParams.get("src");
    fetchUserStatusSignUp();
    if (
      checkInsurancePathName === "/wash-package/" &&
      page_type_src === "application" &&
      page_type !== "" &&
      page_type !== undefined
    ) {
      setCookie("userid", page_type, { path: "/" });
      navigate("/wash-package");
      setSource(page_type_src);
    }
  }, [pathname]);

  // useEffect for Path Navigation and Search Params
  useEffect(() => {
    handleFalse();
    window.scrollTo(0, 0);
    if (
      params.get("user_id") !== "" &&
      params.get("user_id") !== undefined &&
      params.get("src") === "application"
    ) {
      setWashApplicationParams({
        ...washApplicationParamas,
        page_type: params.get("user_id"),
        page_type_src: params.get("src"),
        page_type_email: params.get("email"),
        page_type_mobile: params.get("mobile"),
        page_type_name: params.get("username"),
      });
      navigate("/buy-wash-packages/");
    }
    if (
      checkMobile[0].split("=")[0] === "?user_id" &&
      checkMobile[1].split("=")[0] === "full_name" &&
      checkMobile[2].split("=")[0] === "email" &&
      checkMobile[3].split("=")[0] === "mobile"
    ) {
      setInsuranceUserId(checkMobile);
      navigate("/insurance-claims");
    }
    if (checkInsurancePathName === "/challan/") {
      navigate("/challan/");
    }

    // condition
    if (pathname === "/claim-marsh-insurance/") {
      setUserId(params.get("user_id"));
      setBookingId(params.get("booking_id"));
      navigate("/claim-marsh-insurance/");
    }

    if (pathname === "/monthly-winner/") {
      const luckyWinnerParam = {
        city_id: params.get("city_id"),
      };
      const options = {
        pathname: "/monthly-winner/",
        search: `?${createSearchParams(luckyWinnerParam)}`,
      };
      setCityId(params.get("city_id"));
      navigate(options, { replace: true });
    }
    if (pathname === "/feedback-form/") {
      setDriverID(params.get("driver_id"));
      setImeiNum(params.get("imei_number"));
      setAppUserID(params.get("user_id"));
      setFeedbackScreen(params.get("screen"));
      navigate(`/feedback-form/${search}`);
    }

    if (pathname === "/book-driver/") {
      myGatePaymentCheck.paymentCheck = params.get("token");
      setLat(params.get("lat"));
      setLng(params.get("lng"));
      setPubName(params.get("pub_name"));
      setMyGateToken(params.get("token"));

      // msilToken start here

      setMsilTokenData({
        token: searchParams.get("token"),
        name: searchParams.get("name"),
        utm_source: searchParams.get("utm_source"),
        latitude: searchParams.get("lat"),
        longitude: searchParams.get("lng"),
      });

      navigate("/book-driver/");

      // msil token end here
    }
    if (pathname === "/book-driver/windmills_craftworks") {
      setLat(12.982495856966503);
      setLng(77.72182000732458);
      navigate("/book-driver/windmills_craftworks");
    }
    if (pathname === "/driver/vacation/") {
      setDriverID(params.get("driver_id"));
      setImeiNum(params.get("imei_number"));
      navigate("/driver/vacation/");
    }
    if (pathname === "/track-driver/" || pathname === "/track-driver") {
      setMyGateBookingID(params.get("booking_id"));
      setMyGateWashToken(params.get("my_gate_session_id"));
      setAppCarWashTrackSrc(params.get("track_src"));
      setAppUserID(params.get("user_id"));
      navigate(`/track-driver/${search}`);
    }
    if (pathname === "/track-service/") {
      setTrackServiceSrc(params.get("track_src"));
      setServiceOrderID(params.get("order_id"));
      setServiceMyGateToken(params.get("token"));
      setAppUserID(params.get("user_id"));
      navigate(`/track-service/${search}`);
    }
    if (pathname === "/track-du-daily/") {
      setDuOrderID(params.get("order_id"));
      setAppUserID(params.get("user_id"));
      navigate(`/track-du-daily/${search}`);
    }
    if (pathname === "/track-du-driver/") {
      setBookingId(params.get("booking_id"));
      setAppUserID(params.get("user_id"));
      navigate(`/track-du-driver/${search}`);
    }
    if (pathname === "/du-daily/") {
      const duDailyParamsObject = {
        email: params.get("email"),
        mobile: params.get("mobile"),
        user_id: params.get("user_id"),
        transmission_type: params.get("transmission_type"),
        car_type: params.get("car_type"),
        name: params.get("name"),
        src: params.get("src"),
        is_one_way: params.get("is_one_way"),
      };
      setLat(params.get("lat"));
      setLng(params.get("lng"));
      setDuDailyParams((prevParams) => ({
        ...prevParams, // Spread the previous state
        ...duDailyParamsObject, // Spread the 'params' object to update specific properties
      }));
      navigate(`/du-daily/${search}`);
    }

    if (pathname === "/du-daily-partner/") {
      const duDailyPartnerParamsObject = {
        lat: params.get("latitude"),
        lng: params.get("longitude"),
        driver_id: params.get("driver_id"),
        imei_number: params.get("imei_number"),
        name: params.get("name"),
        mobile: params.get("mobile"),
        email: params.get("email"),
        city_name: params.get("city_name"),
        driver_zone: params.get("driver_zone"),
      };

      setDriverData((prevParams) => ({
        ...prevParams, // Spread the previous state
        ...duDailyPartnerParamsObject, // Spread the 'params' object to update specific properties
      }));
      navigate(`/du-daily-partner/${search}`);
    }

    if (pathname === "/fuel-price/") {
      fuelPriceCoordinate.latitude = params.get("lat");
      fuelPriceCoordinate.longitude = params.get("lng");

      // if (params.get("state") === null || params.get("city") === null) {
      //   setStateFuel({
      //     ...stateFuel,
      //     state: "karnataka",
      //     city: "Bangalore",
      //   });
      // } else {
      //   setStateFuel({
      //     ...stateFuel,
      //     state: params.get("state"),
      //     city: params.get("city"),
      //   });
      // }
      navigate("/fuel-price");
    }

    if (pathname === "/car-service/") {
      carServiceData.latitude = params.get("lat");
      carServiceData.longitude = params.get("lng");
      carServiceData.session_id = params.get("token");
      carServiceData.address = params.get("sname");
      setCategory(params.get("category"));

      navigate("/car-service/");
    }
  }, [pathname]);

  useEffect(() => {
    // Disable the right-click context menu
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    // Add event listener to the entire document to disable the right-click context menu
    document.addEventListener("contextmenu", disableRightClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  // PROFILE API TO IDENTIFY USER IN EVERY PAGE
  function fetchUserStatusSignUp() {
    axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${cookies.userid}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (
          Object.keys(data.pending_actions).length !== 0 &&
          data.is_authenticated === true
        ) {
          setIsAuthenticated(data.is_authenticated);
          setUserName(data.full_name);
          setShowUserId(data.user_id);
          setCookie("userid", data.user_id, { path: "/" });
          setUserInfo(data);
          paymentAPI(data.pending_actions);
        } else {
          setIsAuthenticated(data.is_authenticated);
          setUserName(data.full_name);
          setShowUserId(data.user_id);
          setCookie("userid", data.user_id, { path: "/" });
          setUserInfo(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setIsAuthenticated(false);
      });
  }

  // PAYMENT API TO SHOW DETAILS OF TRIPS
  function paymentAPI(data) {
    axios
      .get(
        `${BASE_URL_HULK}/app/booking-info-v2/?src=website&booking_id=${data.booking_id}`
      )
      .then(({ data }) => {
        if (data.status === "success") {
          data.showCloseButton = false;
          setDetailsResponse(data);
          setShowOnlinePaymentPopUp(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <React.Fragment>
      <div className="App">
        <Routes>
          {/* Define or Write all Routes Here */}
          <Route exact path="/" element={<HomePage />} />
          <Route
            path="/car-drivers-for-hire-hyderabad"
            element={<Hyderabad />}
          />
          <Route
            path="/car-drivers-for-hire-bangalore"
            element={<Bangalore />}
          />
          <Route
            path="/car-drivers-for-hire-ahmedabad"
            element={<Ahmedabad />}
          />

          <Route path="/car-drivers-for-hire-chennai" element={<Chennai />} />
          <Route path="/car-drivers-for-hire-gurgaon" element={<Gurgaon />} />

          <Route
            path="/car-drivers-for-hire-gurgaon/dlf-cyberhub"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/narsinghpur"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/sector54"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/sohna-road"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/golf-course-extension"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/ambience-mall"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/sector82"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/sushant-lok"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/sikanderpur-ghosi"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/palam-vihar"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/nirvana-country"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/manesar"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/southcity"
            element={<GurgaonAllCity />}
          />
          <Route
            path="/car-drivers-for-hire-gurgaon/dlf-city"
            element={<GurgaonAllCity />}
          />

          {/* ===================
            msil Path start here 
          ======================*/}

          <Route path="/msil-order-page" element={<MsilPage />} />

          {/* ===================
            msil Path end here 
          ======================*/}

          <Route path="/car-drivers-for-hire-delhi" element={<DelhiNCR />} />
          <Route path="/car-drivers-for-hire-kolkata" element={<Kolkata />} />
          <Route path="/car-drivers-for-hire-mumbai" element={<Mumbai />} />
          <Route path="/car-drivers-for-hire-pune" element={<Pune />} />
          <Route path="/car-servicing" element={<CarServicing />} />
          <Route path="/recharge-fastag" element={<FastTag />} />
          <Route path="/car-detailing" element={<CarDetailing />} />
          <Route path="/driveu-coins" element={<DriveUCoins />} />
          <Route path="/driver-partners" element={<PartnerPage />} />
          <Route
            path="/roadside-assistance-plans"
            element={<RoadSideAssistance />}
          />
          <Route path="/drivers-for-domestic" element={<DomesticDriver />} />
          <Route path="/drivers-for-daily-commute" element={<DailyCommute />} />
          <Route path="/drivers-for-weekend" element={<Weekend />} />
          <Route path="/drivers-for-outstation" element={<Outstation />} />
          <Route path="/sell-used-cars" element={<SellCar />} />
          <Route path="/temporary-drivers" element={<TemporaryDriver />} />
          <Route path="/hourly-drivers" element={<HourlyDriver />} />
          <Route path="/chauffeur-services" element={<Chauffeurs />} />
          <Route path="/black" element={<DriveUBlack />} />
          <Route path="/drivers-for-night" element={<NightDriver />} />
          <Route path="/drivers-for-short-trips" element={<ShortTrip />} />
          <Route path="/team" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/offers" element={<Offers />} />
          <Route path="/hire-drivers" element={<HireDriver />} />
          <Route path="/compare-Top4CallDrivers/" element={<CallDrivers />} />
          <Route path="/compare-DriversInIndia/" element={<DriversIndia />} />
          <Route path="/compare-DriverOnHire/" element={<DriversOnHire />} />
          <Route path="/compare-EziDrive/" element={<EziDrive />} />

          {/* add new Route start here */}
          <Route path="/compare-Tatd/" element={<Tatd />} />
          <Route path="/compare-GetYourDriver" element={<GetYourDriver />} />
          <Route path="/compare-DriveAssists" element={<DriveAssists />} />
          <Route path="/compare-IndianDriver" element={<IndianDriver />} />
          <Route path="/compare-NammaDriver" element={<NammaDriver />} />
          <Route path="/compare-DriverSuvidha" element={<DriverSuvidha />} />
          <Route path="/compare-DriversKatta" element={<DriverKatta />} />
          <Route path="/compare-Cars24AutoPilot" element={<Cars24Service />} />
          {/* add new Route end here */}

          {/*=========================
             CarCleaning start here 
          ==========================*/}

          <Route path="/car-wash" element={<CarCleaning />} />

          {/*=========================
           CarCleaning end here 
           ==========================*/}

          {/* <Route path="/car-wash" element={<CarWash />} /> */}
          <Route path="/tnc" element={<TermConditionNew />} />
          <Route path="/tncs" element={<TNCPage />} />
          <Route path="/carwash-partner-tnc" element={<CleaningTNC />} />
          <Route path="/driver-tnc" element={<DriverTNC />} />
          <Route path="/b2b-tnc" element={<B2BTNC />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/driver-privacy-policy"
            element={<DriverPrivacyPolicy />}
          />
          <Route path="/latest-offer" element={<LatestProdOffer />} />
          <Route path="/fastag-helpline" element={<FASTagList />} />
          <Route path="/insurance-helpline" element={<InsuranceList />} />
          <Route path="/traffic-alerts" element={<TwitterPage />} />
          <Route path="/garage-form" element={<GarageForm />} />
          <Route path="/garage-list" element={<GarageList />} />
          <Route path="/helpline-number" element={<HelplineLink />} />
          {goToPage ? (
            <Route
              path={`/from/${referralId}/`}
              element={<ReferralPage />}
            ></Route>
          ) : (
            <Route
              path={`/from/${errorReferral}/`}
              element={<ReferralError />}
            ></Route>
          )}
          <Route path="/book-driver/" element={<BookingBlock />} />
          <Route
            path="/book-driver/windmills_craftworks"
            element={<BookingBlock />}
          />
          <Route path="/my-drives/" element={<OrderPage />}></Route>
          <Route
            // path={`/claims/?${checkMobileInsurance[1]}`}
            path="/insurance-claims"
            element={<MobileInsurance />}
          ></Route>
          <Route path="/my-claims" element={<ClaimsPage />}></Route>
          <Route path="/challan/" element={<Challan />}></Route>
          <Route path="/emi/" element={<EMICalculator />}></Route>
          <Route path="/vehicle-recall/" element={<VehicleRecall />}></Route>
          <Route path="/secure-ins-tnc/" element={<MarshIns />}></Route>
          <Route
            path="/claim-marsh-insurance/"
            element={<InsuranceClaimPage />}
          ></Route>
          <Route
            path="/wash-package"
            element={<WashSubscriptionPage />}
          ></Route>
          <Route
            path="/buy-wash-packages/"
            element={<WashSubscription />}
          ></Route>
          <Route path="/active-packages/" element={<CarBikeRenew />}></Route>
          <Route path="/monthly-winner/" element={<LuckyWinner />}></Route>
          <Route path="/driveu-help" element={<DriveuHelp />}></Route>
          <Route path="/carwash-help" element={<CarWashHelp />}></Route>
          <Route
            path="/mygate-driver-help"
            element={<MyGateDriverHelp />}
          ></Route>
          <Route path="/mygate-wash-help" element={<MyGateWashHelp />}></Route>
          <Route path="/du-daily-help" element={<DuDailyHelp />}></Route>
          <Route
            path="/driveu-secure-help"
            element={<MarshInsuranceHelp />}
          ></Route>
          <Route path="/ev-charging" element={<EvChargingHelp />}></Route>
          <Route path="/driver/vacation" element={<VacationPlannerPage />} />
          <Route path="/track-driver" element={<TrackingPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          <Route path="/fuel-price" element={<Fuelprice />} />
          <Route path="/car-service" element={<CarService />} />
          <Route path="/track-service" element={<CarServiceTrack />}></Route>
          <Route path="/feedback-form" element={<FeedbackForm />}></Route>
          <Route path="/du-daily" element={<DriveULandingPage />}></Route>
          <Route
            path="/create-daily-bookings-oneway"
            element={<DUOneWayBooking />}
          ></Route>
          <Route
            path="/create-daily-bookings"
            element={<DriveuDaily />}
          ></Route>
          <Route path="/du-daily-success" element={<DailyThankyou />}></Route>
          <Route path="/track-du-daily/" element={<TrackDuDaily />}></Route>

          <Route path="/track-du-driver" element={<DuBookingTrack />}></Route>
          <Route
            path="/du-daily-partner/"
            element={<DuDailyPartnerLandingPage />}
          ></Route>
          <Route
            path="/du-daily-partner-all-bookings"
            element={<AllTypesBooking />}
          ></Route>
          <Route
            path="/du-daily-partner-status"
            element={<StatusPackage />}
          ></Route>
          <Route
            path="/du-daily-partner-assigned"
            element={<AssignedPartnerPage />}
          ></Route>

          <Route path="/delete-account" element={<AccountDelete />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
      <Toaster position="top-right" reverseOrder={false} />

      <>
        {/* This Condition based on MyGate Payment Integration */}
        {(myGatePaymentCheck.paymentCheck === "" ||
          myGatePaymentCheck.paymentCheck === null) &&
        showOnlinePaymentPopUp === false ? null : showOnlinePaymentPopUp &&
          (myGatePaymentCheck.paymentCheck === "" ||
            myGatePaymentCheck.paymentCheck === null) ? (
          <DriverDetails />
        ) : null}
      </>

      <>
        {/* This Condition based on MyGate Payment Integration */}
        {myGatePaymentCheck.paymentCheck === "" ||
        showOnlinePaymentPopUp === false ? null : showOnlinePaymentPopUp &&
          (myGatePaymentCheck.paymentCheck === "" ||
            myGatePaymentCheck.paymentCheck === null) ? (
          <div className="overlay" />
        ) : null}
      </>

      <>
        {/* This Condition not based on MyGate Payment Integration */}
        {/* {showOnlinePaymentPopUp ? <DriverDetails /> : null}
      {showOnlinePaymentPopUp ? <div className="overlay" /> : null} */}
      </>

      <>
        {JSON.stringify(detailsResponse) === JSON.stringify({}) ? null : (
          <PaymentDetails />
        )}
      </>
    </React.Fragment>
  );
}

export default App;
