import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FooterContext } from "../../context/FooterContextProvider";
import "./Footer.css";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";

function Footer() {

  const utmSource = localStorage.getItem("utm_source") || "website";
  const utmMedium = localStorage.getItem("utm_medium") || "storebadge";
  const utmCampaign = localStorage.getItem("utm_campaign") || "homepage";

  const [playStore, setPlayStore] = useState(`https://play.google.com/store/apps/details?id=com.humblemobile.consumer&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);
  const [appStore, setAppStore] = useState(`https://apps.apple.com/in/app/driveu-hire-drivers-services/id1037756294?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&page_title=${window.location.pathname}`);

  useEffect(() => {
    const generateLink = window.generatedLink;

    if (generateLink) {
      if (isMobile) {
        setPlayStore(generateLink);
        setAppStore(generateLink);
      }
    }
  }, []);
  const { handleFooter } = useContext(FooterContext);

  const addingGaEventInPlayAndAppStore = (url , downloadApp) =>{
    ReactGA.gtag("event", "footer_download_app",{
      destination : downloadApp
    });

    window.location.href = url
  }

  const goToAppStore = () => addingGaEventInPlayAndAppStore(appStore, "appstore");
  const goToPlayStore = () => addingGaEventInPlayAndAppStore(playStore , "playstore");
  return (
    <Fragment>
      <div className="main_Div">
        <div className="first_div">
          <div className="first_fir">
            <Link onClick={handleFooter} className="first_fir_first" to="/">
              <img
                className="footer-logo"
                src="https://ik.imagekit.io/driveu/Website/logo_P9eM3dzW_.png"
                alt="driveUlogo"
              />
            </Link>
            <div className="follow">Follow Us</div>
            <div className="App_Image">
              <div className="image_height_width">
                <a
                  href="https://www.facebook.com/driveu.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://ik.imagekit.io/driveu/Website/facebook_4zPzpF8IO.png"
                    alt="facebook"
                    className="socialmedia-icon"
                  />
                </a>
              </div>
              <div className="image_height_width">
                <a
                  href="https://www.instagram.com/driveu.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="socialmedia-icon"
                    src="https://ik.imagekit.io/driveu/Website/insta_5-zCH9-cv3.png"
                    alt="instagram"
                  />
                </a>
              </div>
              <div className="image_height_width">
                <a
                  href="https://www.youtube.com/@DriveuIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="socialmedia-icon"
                    src="https://ik.imagekit.io/driveu/Website/youtube_7rjabc-EY.png"
                    alt="youtube"
                  />
                </a>
              </div>
              <div className="image_height_width">
                <a
                  href="https://www.twitter.com/driveu_in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="socialmedia-icon"
                    src="https://ik.imagekit.io/driveu/Website/twiter_jD0EWqE2M.png"
                    alt="twitter"
                  />
                </a>
              </div>
              <div className="image_height_width">
                <a
                  href="https://www.linkedin.com/company/driveu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="socialmedia-icon"
                    src="https://ik.imagekit.io/driveu/Website/in_daV93VMKq.png"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* <!-- 2nd --> */}
          <div className="first_sec">
            <div className="first_sec_first">About</div>
            <div className="first_sec_sec size">
              <Link to="/team">Team</Link>
            </div>
            <div className="size">
              <a
                href="https://www.driveu.in/blog/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </div>
            <div className="size">
              <Link to="/contact-us">Contact Us</Link>
            </div>
            <a
              href="/driveu-help"
              className="size"
              style={{ color: "rgba(255, 255, 255, 0.7)", cursor: "pointer" }}
            >
              Chat with us
            </a>
          </div>

          {/* <!-- 3rd --> */}
          <div className="first_thi">
            <div className="first_thi_first ">Services</div>
            <div className="first_thi_sec size">
              <Link to="/hire-drivers">Hire a Driver</Link>
            </div>
            <div className="size">
              <Link to="/temporary-drivers">Hire a Temporary Driver</Link>
            </div>
            <div className="size">
              <Link to="/hourly-drivers">Hire Hourly Drivers</Link>
            </div>
            <div className="size">
              <Link to="/chauffeur-services">Hire Chauffeurs</Link>
            </div>
            <div className="size">
              <Link to="/offers">DriveU Offers</Link>
            </div>
            <div className="size">
              <Link to="/black">DriveU Black</Link>
            </div>
            {/* <div className="size">
              <a href="/hire-safe-car-drivers/">DriveU F.A.S.T.R Safety</a>
            </div> */}
          </div>

          {/* <!-- 4th --> */}
          <div className="first_fou">
            <div className="first_fou_first ">For&nbsp;Business&nbsp;</div>
            <div className="size">
              {/* <a href="/features/">Features</a> */}
            </div>
            {/* <div className="size">
              <a href="/pricing/">Pricing</a>
            </div> */}
            <div className="size">
              <a
                href="https://www.driveu.in/business/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hire Drivers For B2B
              </a>
            </div>
            <div className="size">
              <Link to="/driver-partners">Join as Driver Partner</Link>
            </div>
          </div>
          {/* <!-- 5th --> */}
          <div className="first_fif">
            <div className="first_fif_first ">Cities</div>
            <div className="first_fif_sec size">
              <Link to="/car-drivers-for-hire-bangalore">Bangalore</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-chennai">Chennai</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-delhi">Delhi NCR</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-gurgaon">Gurgaon</Link>
            </div>

            <div className="size">
              <Link to="/car-drivers-for-hire-hyderabad">Hyderabad</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-kolkata">Kolkata</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-mumbai">Mumbai</Link>
            </div>
            <div className="size">
              <Link to="/car-drivers-for-hire-pune">Pune</Link>
            </div>
          </div>
          {/* <!-- 6th --> */}
          <div className="first_six">
            <div className="first_six_first ">Compare</div>
            <div className="first_six_sec size">
              <Link to="/compare-EziDrive/">Ezi Drive</Link>
            </div>

            <div className="size">
              <Link to="/compare-Top4CallDrivers/">Top4 Call Drivers</Link>
            </div>
            <div className="size">
              <Link to="/compare-DriversInIndia/">Driversindia</Link>
            </div>
            <div className="size">
              <Link to="/compare-DriverOnHire/">Driversonhire</Link>
            </div>
            <div className="size">
              <Link to="/compare-GetYourDriver">Get Your Driver</Link>
            </div>
            <div className="size">
              <Link to="/compare-DriveAssists">Drive Assists</Link>
            </div>
            <div className="size">
              <Link to="/compare-IndianDriver">Indian Driver</Link>
            </div>
            <div className="size">
              <Link to="/compare-NammaDriver">Namma Driver</Link>
            </div>
            <div className="size">
              <Link to="/compare-DriverSuvidha">Driver Suvidha</Link>
            </div>
            <div className="size">
              <Link to="/compare-DriversKatta">Drivers Katta</Link>
            </div>
            <div className="size">
              <Link to="/compare-Tatd">Tatd</Link>
            </div>
            <div className="size">
              <Link to="/compare-Cars24AutoPilot">Cars24AutoPilot</Link>
            </div>
          </div>
        </div>
        {/* <!-- second div --> */}
        <div className="second_div">
          {/* <!-- first div beside second div --> */}
          <div className="second_first">
            <div className="term">
              <span>
                <Link to="/tncs">Terms & Conditions&nbsp;|&nbsp;</Link>
              </span>
              <span>
                <Link to="/privacy-policy">Privacy Policy&nbsp;|&nbsp;</Link>
              </span>
              <span>
                <Link to="/refund-policy">Refund & Cancellation Policy</Link>
              </span>
            </div>
            <div
              className="footer_text"
              style={{ color: "white", opacity: 0.7 }}
            >
              © Humble Mobile Solutions Pvt. Ltd. All Rights Reserved
            </div>
            <div
              className="footer_text"
              style={{ color: "white", opacity: 0.7 }}
            >
              112, 17th Main Rd, 5th Block, Koramangala, Bengaluru, Bengaluru
              Urban, Karnataka 560095. All Rights Reserved.
            </div>
          </div>
          {/* <!-- second div beside second div --> */}
          <div className="second_sec">
            <div className="app_image_div" >
              <div onClick={goToAppStore}>
                <img
                  src="https://ik.imagekit.io/driveu/Website/2_9snS8wDMo.png"
                  className="storedownload"
                  alt="AppStore"
                />
              </div>
            </div>
            <div className="app_image_div">
              <div onClick={goToPlayStore}>
                <img
                  src="https://ik.imagekit.io/driveu/Website/1_kx2JKsHQC.png"
                  className="storedownload"
                  alt="PlayStore"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
