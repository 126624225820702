import React, { Fragment, useContext, useEffect } from "react";
import NavbarContainer from "../../parts/Navbar/NavbarContainer";
import "../../Components/Home/LottieContent/LottieContent.css";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { DownloadContext } from "../../context/DownloadContextProvider";
import BuyAndSell from "../../Components/Home/BuyAndSell/BuyAndSell";
import Footer from "../../parts/Footer/Footer";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import MobileAbout from "../../parts/DropDown/MobileAbout/MobileAbout";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import GurgaonCityPage from "../../Components/Cities/Gurgaon/GurgaonCity";
import gurgaonImage from "../../Assets/Webp/gurgaon-01.webp"
import ReactGA from "react-ga4";

function Gurgaon() {
  const navigate = useNavigate();
  const { showMediaIcons, handleFalse } = useContext(HamburgerContext);
  const { showPopUp, setShowPopUp, handleScroll } = useContext(DownloadContext);
  const { isAuthenticated } = useContext(BookingFlowParaContext);

  const handleScrollDownload = () =>{
    ReactGA.gtag("event", "city_download_app", {    
      position: "top", 
      destination : "scroll"
    });
    handleScroll();
  }
  const handleDownloadAppForMobile = () =>{
    ReactGA.gtag("event", "city_download_app", {
      position : "top",
      destination : "generic_download"
    });
    window.location.href = window.generatedLink;
  }

  const handleBookDriver = () => {
    ReactGA.gtag("event", "city_book_now_clicked");
    if (isAuthenticated === true) {
      navigate("/book-driver/");
    } else if (isAuthenticated === false) {
      setShowPopUp(true);
    }
  };
  useEffect(() => {
    if (showPopUp) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showPopUp]);

  const hideHamburger = () => {
    handleFalse();
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Hire DriveU Verified car drivers for your car in Gurgaon for local and
          outstation trips on hourly basis
        </title>
        <meta
          name="description"
          content="Hire background-verified, tested and trained professional car drivers, starting at ₹129 per hour, trusted by 7.5 Lakh+ Car Owners. Book an experienced DriveU driver in Gurgaon for one-way and round trips. Hourly Packages for local and outstation trips also available. Get a car driver for office commute, shopping & errands, medical visits, after-party drops and outstation trips."
        />
        <meta
          name="keywords"
          content="car drivers in Gurgaon , Gurgaon car driver services, car drivers in Gurgaon for hire, car driver services in Gurgaon , car drivers for hire in Gurgaon , car drivers for rent in Gurgaon , car driver service in Gurgaon , car driver for hire in Gurgaon , car driver agency Gurgaon , car drivers in Gurgaon for rent, driver agency in Gurgaon , car driver service Gurgaon , Gurgaon car drivers services, hire a car driver in Gurgaon , hire car drivers in Gurgaon , book car drivers in Gurgaon"
        />
      </Helmet>
      <NavbarContainer />
      <div
        style={{ display: showMediaIcons ? "" : "none" }}
        className="MobileView_Option"
      >
        <MobileAbout />
      </div>
      <div onClick={hideHamburger}>
        <Fragment>
          <div className="container">
            <div className="wrapper-content-lottie">
              <div className="wrapper-content">
                <div className="content_hire">
                  <div className="content_subsection">
                    HIRE DRIVERS IN GURGAON
                  </div>
                  <div className="content_section_heading">
                    Ambience Mall to Sultanpur
                  </div>
                  <div className="content_subsection_heading">
                    Let us DriveU to your favourite people and places
                  </div>
                  <div className="btn_download_bookDriver">
                    <button onClick={handleBookDriver} className="book_driver">
                      Book Driver
                    </button>
                    <button className="download_app" onClick={handleScrollDownload}>
                      Download App
                    </button>
                  </div>
                </div>
              </div>
              <div className="wrapper-lottie">
                <div
                  className="content_lottie"
                  id="content_lottie"
                  style={{ width: "90%" }}
                >
                  <img
                    style={{ height: "100%", width: "100%" }}
                    // src="https://ik.imagekit.io/driveu/Web/genericcitypage_e5HlCU-4R.png"
                    src={gurgaonImage}
                    alt="DelhiNCRCity"
                  />
                </div>
                <div className="btn-app-download-mobile">
                  <button
                    onClick={handleBookDriver}
                    className="book_now_mobile"
                  >
                    Book Now
                  </button>
                  <button className="download_app_mobile">
                    <p
                      onClick={handleDownloadAppForMobile}
                      style={{
                        textDecoration: "none",
                        color: "#09081a",
                      }}
                    >
                      Download App
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showPopUp ? <div className="overlay" /> : null}
              {showPopUp ? <RegistrationForm className="form_number" /> : null}
            </div>
          </div>
        </Fragment>
        <GurgaonCityPage />
        <BuyAndSell />
        <Footer />
      </div>
    </div>
  );
}

export default Gurgaon;
